import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Investissement from '../../components/Investissement'
import baby from '../../../static/img/baby.svg'

const InvestissementPage = ({
  data: {
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <Helmet title={`Nos Investissements | ${title}`} />
    <header className="main-header front-header">
      <div className="main-header-text">
        <h1 className="main-h-text-1">Nos Investissements</h1>
        <span className="main-h-text-2">Dernière mise à jour 26/11/2021</span>
      </div>
    </header>
    <main className="category investissement">
      <div className="breadcrumbs transparent">
        <div className="breadcrumb-links">
          <Link className="bread-item" to="/">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22"><path d="M21 12v10h-6v-6H9v6H3V12H0L12 0l12 12zm-1-5.907V1h-3v2.093z"></path></svg>
          </Link>
            ❯
          <Link className="bread-item on" to="#">Nos Investissements</Link>
        </div>
      </div>
      <div className="list">
      <Investissement 
          info={{
            title: "Livret A - Epargne Précaution",
            icon: "/img/saving.svg",
            valeur: "16000",
            pie: null,
            cetteAnnee: "0.5"
          }}
        />
        <Investissement 
          info={{
            title: "Assurance Vie - Court",
            icon: "/img/travel.svg",
            valeur: "9000",
            pie: null,
            cetteAnnee: "2.5"
          }}
        />
        <Investissement 
          info={{
            title: "Assurance Vie - Long",
            icon: "/img/long.svg",
            valeur: "84000",
            pie: null,
            cetteAnnee: "31"
          }}
        />
        <Investissement 
          info={{
            title: "Assurance Vie - Enfants",
            icon: baby,
            valeur: "2400",
            pie: null,
            cetteAnnee: "28"
          }}
        />
        <Investissement 
          info={{
            title: "PEA pour nos retraite",
            icon: "/img/retraite.svg",
            valeur: "32000",
            pie: "/img/composition-de-notre-pea.svg",
            cetteAnnee: "32"
          }}
        />
      </div>
    </main>
  </Layout>
)

export default InvestissementPage

export const investissementPageQuery = graphql`
  query InvestissementQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
