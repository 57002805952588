import React from 'react'

//const Investissement = ({ info }) => {
  class Investissement extends React.Component {

    constructor(props) {
      super(props);
      this.state={
        show: false,
      }
    }

    toogle() {
      this.setState((currentState) => ({
        show: !currentState.show, 
    }));
    }

    render() {
  const { valeur, title , icon, cetteAnnee, pie } = this.props.info;
console.log(pie);
  return(
<span>
  <div className="dropdown-container">
    <header className={"dd-header" + (this.state.show ? ' no-border' : '')}  onClick={() => this.toogle()}>
      <div className="dd-h-l">
        <div className="portfolio-icon">
          <img src={icon} alt="Icon du portefeuille"/>
        </div>
        <h3 className="card-title">
          <span className="inv-name">
            {title}
          </span>
        </h3>
      </div>
      <div className="dd-h-r">
        <div>Valeur</div>
        <div>
          <span>{valeur}€</span>
        </div>
      </div>
    </header>
    <article className={this.state.show ? '' : 'hidden'}>
      <div className="wrapper">
        <div className="dd-grid">
          <div className="dd-grid-item dd-grid-item-x1">
            <div className="item-gridz">
              <div className="open-stars-content positive">
                <div className="collapse-text">
                  <h4 className="collapse-card-header">Valeur</h4>
                  <div className="card-icon-collapse">
                    <span className="inv-ihMain">{valeur}</span>
                    <span>€</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dd-grid-item dd-grid-item-x2">
            <div className="item-gridz">
              <div className="open-stars-content positive">
                <div className="collapse-text">
                  <h4 className="collapse-card-header">Cette année</h4>
                  <div className="card-icon-collapse">
                    <span className="inv-ihMain">{cetteAnnee}</span>
                    <span>%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {pie ? (<div className="dd-grid-item dd-grid-item-x5 main-chart">
            <img src={pie} alt="Contenu"/>
          </div>) : ''}
        </div>
      </div>
    </article>
  </div>
</span>
  )}

}

export default Investissement